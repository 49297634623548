module.exports = [{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"environment":"production","release":"main","dsn":"https://a1671e334e4c4b14be27ce27781d3e57@o578383.ingest.sentry.io/6327934","sampleRate":0.7},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Evoped® Bezirk Gmünd ","short_name":"Evoped® Bezirk Gmünd ","start_url":"/","background_color":"#FEBC28","theme_color":"#FEBC28","display":"minimal-ui","legacy":false,"icon":"/opt/build/repo/static/appicon-4a3b85da-85f7-40ca-b158-879574488b31.png","theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7d643d70b7bdc085f5d275eae194087f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"environments":["production"]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
